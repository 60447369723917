const topMain = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {


    const jsSecMain = document.getElementById("jsSecMain");
    const slideBox = jsSecMain.querySelectorAll(".js-slide-box");
    const totalSlides = slideBox.length;
    let currentIndex = 3;
    let gap = 6000; //スライド間の遅延
    let id

    function showNextSlide() {
      // 現在のスライドとそのコピーから 'act' クラスを削除
      slideBox[currentIndex].classList.remove('act');
      // ２枚目のコピーを３枚目も継続
      if (currentIndex == 1) {
        console.log(currentIndex)
      } else {
        slideBox[currentIndex].querySelector(".js-copy").classList.remove('act');
        slideBox[1].querySelector(".js-copy").classList.remove('act');
      }

      // 次のスライドのインデックスを計算
      currentIndex = (currentIndex + 1) % totalSlides;
      console.log(currentIndex)

      // 次のスライドとそのコピーに 'act' クラスを追加
      slideBox[currentIndex].classList.add('act');
      // 'act' クラスを遅延させて追加する
      setTimeout(() => {
        slideBox[currentIndex].querySelector(".js-copy").classList.add('act');
      }, 1000); // 1000ミリ秒の遅延

      // 指定された遅延後に再び関数を呼び出す
      // setTimeout(showNextSlide, gap);
      id = setTimeout(() => {
        showNextSlide()
      }, gap);

      if (currentIndex == 3) {
        clearTimeout(id);
      }
    }

    // スライドショーを開始するための初回の呼び出し
    showNextSlide();



  }

})()
export default topMain;