const funcLogo = (() => {


  var header = document.querySelector('header.header');

  window.addEventListener('scroll', function () {

    var section = document.querySelector('.sec-main');
    var logo = document.getElementById('jsLogoBottom');
    var offset = section.offsetTop + section.offsetHeight;

    // if (window.pageYOffset > offset) {
    // logo.classList.remove('show');
    // }

    if (window.pageYOffset > offset) {
      header.classList.add('act')
      // logo.style.opacity = '0';
    } else {
      header.classList.remove('act')
      // logo.style.opacity = '1';
    }
  });



})();
export default funcLogo;