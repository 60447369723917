const topModal = (() => {

  const pagePosition = (document.getElementById("pagePosition") != null);
  if (pagePosition) {


    // let sW = window.innerWidth;
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      disableFocus: true,
    });


  }

})()
export default topModal;