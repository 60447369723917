const topMain = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {


    const jsSecMain = document.getElementById("jsSecMain");
    const slideBox = jsSecMain.querySelectorAll(".js-slide-box");
    const totalSlides = slideBox.length;
    let currentIndex = 6;
    let gap = 6000; //スライド間の遅延

    function showNextSlide() {
      // 現在のスライドとそのコピーから 'act' クラスを削除
      slideBox[currentIndex].classList.remove('act');
      slideBox[currentIndex].querySelector(".js-copy").classList.remove('act');

      // 次のスライドのインデックスを計算
      currentIndex = (currentIndex + 1) % totalSlides;

      // 次のスライドとそのコピーに 'act' クラスを追加
      slideBox[currentIndex].classList.add('act');
      // 'act' クラスを遅延させて追加する
      setTimeout(() => {
        slideBox[currentIndex].querySelector(".js-copy").classList.add('act');
      }, 1000); // 1000ミリ秒の遅延

      // 指定された遅延後に再び関数を呼び出す
      setTimeout(showNextSlide, gap);
    }

    // スライドショーを開始するための初回の呼び出し
    showNextSlide();





    // let sW = window.innerWidth;

    // const jsSecMain = document.getElementById("jsSecMain")
    // const slideBox = jsSecMain.querySelectorAll(".js-slide-box")
    // const slideBoxEnd = jsSecMain.querySelector(".js-slide-box-end")

    // let gap = 1000

    // slideBox[0].classList.add('act')
    // setTimeout(() => {
    //   slideBox[0].querySelector(".js-copy").classList.add('act')
    // }, gap * 1);

    // setTimeout(() => {
    //   slideBox[1].classList.add('act')
    // }, gap * 4);
    // setTimeout(() => {
    //   slideBox[1].querySelector(".js-copy").classList.add('act')
    // }, gap * 5);
    
    // setTimeout(() => {
    //   slideBox[2].classList.add('act')
    // }, gap * 8);
    // setTimeout(() => {
    //   slideBox[2].querySelector(".js-copy").classList.add('act')
    // }, gap * 9);
    
    // setTimeout(() => {
    //   slideBox[3].classList.add('act')
    // }, gap * 12);
    // setTimeout(() => {
    //   slideBox[3].querySelector(".js-copy").classList.add('act')
    // }, gap * 13);

    
    // if (sW > 750) {

    //   setTimeout(() => {
    //     slideBoxEnd.classList.add('act')
    //   }, gap * 16);
    //   setTimeout(() => {
    //     slideBoxEnd.querySelector(".js-img").classList.add('act')
    //   }, gap * 17);
    //   setTimeout(() => {
    //     slideBoxEnd.querySelector(".js-logo").classList.add('act')
    //   }, gap * 20);
    //   setTimeout(() => {
    //     slideBoxEnd.querySelector(".js-copy").classList.add('act')
    //   }, gap * 21);

    // } else {

    //   setTimeout(() => {
    //     slideBoxEnd.classList.add('act')
    //   }, gap * 16);
    //   // setTimeout(() => {
    //   //   slideBoxEnd.querySelector(".js-img").classList.add('act')
    //   // }, gap * 10);
    //   setTimeout(() => {
    //     slideBoxEnd.querySelector(".js-logo").classList.add('act')
    //   }, gap * 18);
    //   setTimeout(() => {
    //     slideBoxEnd.querySelector(".js-copy").classList.add('act')
    //   }, gap * 19);
    // }





    // const swiper = new Swiper(jsSwiper, {
    //   effect: 'fade', // フェードモードにする（デフォルトは 'slide'）
    //   fadeEffect: {
    //     crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
    //   },

    //   slidesPerView: 1, // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    //   spaceBetween: 0, // スライド間の余白（px）
    //   centeredSlides: true, // アクティブなスライドを中央に配置する

    //   loop: true,  // 無限ループさせる
    //   loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数

    //   speed: 300, // スライドアニメーションのスピード（ミリ秒）

    //   autoplay: { // 自動再生させる
    //     delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
    //     disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
    //     waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    //   },

    //   // grabCursor: true, // PCでマウスカーソルを「掴む」マークにする
    //   // watchSlidesProgress: true, // スライドの進行状況を監視する

    //   // pagination: {
    //   //   el: '.swiper-pagination', // ページネーション要素のクラス
    //   //   clickable: true, // クリックによるスライド切り替えを有効にする
    //   //   type: 'bullets' // 'bullets'（デフォルト） | 'fraction' | 'progressbar'
    //   // },

    //   // navigation: {
    //   //   nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
    //   //   prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    //   // },

    //   // scrollbar: {
    //   //   el: '.swiper-scrollbar', // スクロールバー要素のクラス
    //   // },

    //   // thumbs: {
    //   //   swiper: mySwiper2 // サムネイルのスライダーのインスタンス名
    //   // },

    //   // breakpoints: { // ブレークポイント
    //   //   600: { // 画面幅600px以上で適用
    //   //     slidesPerView: 2,
    //   //   },
    //   //   1025: { // 画面幅1025px以上で適用
    //   //     slidesPerView: 4,
    //   //     spaceBetween: 32,
    //   //   }
    //   // },

    //   on: { // イベントを登録する
    //     slideChange: (swiper) => {
    //       console.log('Slide index changed to: ' + (swiper.realIndex + 1));
    //     },
    //   },
    // });


  }

})()
export default topMain;