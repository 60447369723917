const positionTab = (() => {

  const pagePosition = (document.getElementById("pagePosition") != null);
  if (pagePosition) {

    let btn = document.querySelectorAll('.js-tab-btn');
    // let map = document.querySelectorAll('.js-tab-map');
    let flexArea = document.querySelectorAll('.js-tab-flex');

    for (let i = 0; i < btn.length; i++) {
      btn[i].addEventListener("click", () => {

        // 押されたiとjが同じだったらactとらない
        for (let j = 0; j < btn.length; j++) {
          if (!(j == i)) {
            // console.log(j);
            btn[j].classList.remove("act");
            // map[j].classList.remove("act");
            flexArea[j].classList.remove("act");
          }
        }

        btn[i].classList.add('act');
        // map[i].classList.add('act');
        flexArea[i].classList.add('act');
      });
    }

  }

})()
export default positionTab;