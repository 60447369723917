import { funcScrollControl } from './index';

const funcLoad = () => {


  let loadWrap = document.getElementById('jsLoadWrap');
  loadWrap.classList.add('no-act');
  funcScrollControl.returnScroll();


}
export default funcLoad;