const funcNav = (() => {

  let nav = document.getElementById('jsNav');
  let navBtn = document.getElementById('jsNavBtn');
  let navTxt = document.getElementById('jsNavTxt');
  
  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if( navBtn.classList.contains('act') == true ){
      nav.classList.remove('act');
      nav.classList.add('no-act');
      navBtn.classList.remove('act');
      navTxt.textContent = 'MENU';
    }else{
      nav.classList.remove('no-act');
      nav.classList.add('act');
      navBtn.classList.add('act');
      navTxt.textContent = 'CLOSE';
    }
  });

})();
export default funcNav;