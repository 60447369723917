const lifeInfo = (() => {

  const pageLocation = (document.getElementById("pageLocation") != null);
  if (pageLocation) {

    {
      let tab = document.querySelectorAll('.js-tabs');
      let listBox = document.querySelectorAll('.js-list-box');

      for (let i = 0; i < tab.length; i++) {
        tab[i].addEventListener("click", () => {

          // 押されたiとjが同じだったらactとらない
          for (let j = 0; j < tab.length; j++) {
            if (!(j == i)) {
              // console.log(j);
              tab[j].classList.remove("act");
              listBox[j].classList.remove("act");
            }
          }

          tab[i].classList.add('act');
          listBox[i].classList.add('act');

        });
      }




      const lifeinfo = (set) => {

        // 共通変数
        let facility = [] // 全リストの配列
        for (let i = 0; i < set.itemName.length; i++) {
          facility.push([])
        }
        let map;
        let point;
        let marker = [];
        let infoWindow = [];

        // CSV読み込み
        const getCSV = (() => {
          let req = new XMLHttpRequest();
          req.open("get", set.csvFile, true); // アクセスするファイルを指定
          req.send(null);
          req.onload = function () {
            convertCSVtoArray(req.responseText); // 渡されるのは読み込んだCSVデータ
          }
        })()

        // CSVから配列に変換
        function convertCSVtoArray(str) {
          let csvdata = str.split("\n"); // 改行を区切り文字として行を要素とした配列を生成
          let csvdatalist = []; // 最終的な二次元配列を入れるための配列


          for (let i = 0; i < csvdata.length; i++) {
            csvdatalist[i] = csvdata[i].split(',');
          }

          for (let j = 0; j < set.itemName.length; j++) {
            for (let i = 0; i < csvdatalist.length; i++) {
              if (csvdatalist[i][0].indexOf(set.itemName[j]) !== -1) {
                facility[j].push(csvdata[i].split(','));
              }
            }
          }
          // console.log(facility);
          outDataBox();
          tabBtn(0);
        }

        // 地図の作成
        map = new google.maps.Map(document.getElementById(set.mapElem), {
          center: new google.maps.LatLng(set.mapLatLng[0], set.mapLatLng[1]),
          zoom: set.mapZoom // 地図のズームを指定
        });
        point = new google.maps.Marker({
          position: new google.maps.LatLng(set.pointLatLng[0], set.pointLatLng[1]),
          map: map,
          icon: {
            url: set.pinPath + set.pointFile,
            scaledSize: new google.maps.Size(set.pointSize[0], set.pointSize[1])
          }
        });

        // データボックス生成
        const outDataBox = () => {
          console.log(facility);
          for (let j = 0; j < set.tabBoxId.length; j++) {
            let dataBox = document.getElementById(set.tabBoxId[j]);
            let insertElement = '';
            for (let i = 0; i < facility[j].length; i++) {
              insertElement += '<div class="data data' + j + '_' + i + '">';
              insertElement += '<div class="data_img"><img src="' + set.pinPath + String(j) + ('00' + (i + 1)).slice(-2) + '.png"></div>';
              insertElement += '<div class="data_txt"><p class="data_txt1">' + facility[j][i][1] + '</p>';
              insertElement += '<p class="data_txt2">' + facility[j][i][2] + facility[j][i][3] + '分（約' + Number(facility[j][i][4]).toLocaleString() + 'm）</p>';
              insertElement += '</div></div>';
            }
            dataBox.innerHTML = insertElement;
          }
        }

        //生成済マーカーを順次すべて削除する
        const deleteMakers = () => {
          for (let i = 0; i < marker.length; i++) {
            marker[i].setMap(null);
          }
          marker = []; //参照を開放
        }

        // マーカー毎の処理
        const tabBtn = (tabNum) => {
          deleteMakers();
          var markerLatLng;
          for (let i = 0; i < facility[tabNum].length; i++) {
            markerLatLng = new google.maps.LatLng({ lat: Number(facility[tabNum][i][5]), lng: Number(facility[tabNum][i][6]) }); // 緯度経度のデータ作成
            marker[i] = new google.maps.Marker({ // マーカーの追加
              position: markerLatLng, // マーカーを立てる位置を指定
              map: map // マーカーを立てる地図を指定
            });
            infoWindow[i] = new google.maps.InfoWindow({ // 吹き出しの追加
              content: '<div class="sample">' + facility[tabNum][i][1] + '</div>' // 吹き出しに表示する内容
            });
            marker[i].setOptions({// TAM 東京のマーカーのオプション設定
              icon: {
                url: set.pinPath + (String(tabNum) + ('00' + (i + 1)).slice(-2)) + '.png',// マーカーの画像を変更
                // scaledSize: new google.maps.Size(32, 60)
                scaledSize: new google.maps.Size(set.pinSize[0], set.pinSize[1])
              }
            });
            markerEvent(i, tabNum); // マーカーにクリックイベントを追加
          }
        }

        // マーカーにクリックイベントを追加
        const markerEvent = (i, tabNum) => {
          const mapMove = () => {
            for (let j = 0; j < infoWindow.length; j++) {
              infoWindow[j].close();
            };
            infoWindow[i].open(map, marker[i]); // 吹き出しの表示
            let movePos = new google.maps.LatLng(Number(facility[tabNum][i][5]), Number(facility[tabNum][i][6])); // 地図の中心を移動 
            map.panTo(movePos);
          }
          marker[i].addListener('click', () => { // マーカーをクリックしたとき
            mapMove();
          });
          let data = document.querySelectorAll('.data' + tabNum + '_' + i + '');
          for (let i = 0; i < data.length; i++) {
            data[i].addEventListener('click', () => {
              mapMove();
            });
          }
        }



        for (let i = 0; i < set.tabBtnId.length; i++) {
          document.getElementById(set.tabBtnId[i]).onclick = () => {
            tabBtn(i);
          };
        }

      }


      const setting = {
        csvFile: 'assets/lifeinfo/csv/csv.csv', // CSVファイル
        itemName: ['商業施設', '公共施設', '医療施設', '教育施設', '公園', '文化・レジャー施設'], // リストの項目名
        mapElem: 'jsMap', // mapID
        mapLatLng: [35.826963, 139.579918], // map座標
        mapZoom: 15, // mapZoom
        pinPath: 'assets/lifeinfo/pin/', // pinPath
        pointFile: 'point.png', // pointFile
        pointLatLng: [35.826963, 139.579918], // point座標
        pointSize: [90, 33], // logoSize
        pinSize: [22.4, 42], // pinSize
        tabBtnId: ['btn1', 'btn2', 'btn3', 'btn4', 'btn5', 'btn6'], // タブボタンID
        tabBoxId: ['box1', 'box2', 'box3', 'box4', 'box5', 'box6'], // データボックスID
      }
      lifeinfo(setting);
    }


  }
})()
export default lifeInfo;