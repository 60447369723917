const comImgFade = (() => {

  const fadeElem = document.querySelectorAll('.js-com-img-fade');
  if (fadeElem.length > 0) {

    for (let i = 0; i < fadeElem.length; i++) {

      gsap.set(fadeElem[i], {
        opacity: 0,
        scale: 1.1,
        filter: "blur(10px)",
      });

      gsap.to(fadeElem[i], {
        opacity: 1,
        scale: 1,
        filter: "blur(0px)",
        duration: 2.0,
        ease: "power1.out",
        scrollTrigger: {
          trigger: fadeElem[i],
          start: 'top 80%'
        }
      });

    }

  }

})();
export default comImgFade;