const topModalSlide = (() => {

  const pagePosition = (document.getElementById("pagePosition") != null);
  if (pagePosition) {


    let btn = document.querySelector('.js-modal-btn');

    // let sW = window.innerWidth;


    let hlag = 0;
    btn.addEventListener("click", () => {
      hlag++;
      if (hlag === 1) {

        const swiper = new Swiper(".swiper", {
          loop: true,
          effect: 'fade',
          speed: 1000,
          // effect: 'fade',
          slidesPerView: 1, // 一度に表示する枚数
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          // thumbs: {
          //   swiper: sliderThumbnail
          // },
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          // },
        });

      }
    });


  }

})()
export default topModalSlide;