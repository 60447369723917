const funcScrollControl = {
  // スクロール禁止
  noScroll: () => {
    document.addEventListener("wheel", funcScrollControl.scrollControl, { passive: false });
    document.addEventListener("touchmove", funcScrollControl.scrollControl, { passive: false });
  },
  // スクロール禁止解除
  returnScroll: () => {
    document.removeEventListener("wheel", funcScrollControl.scrollControl, { passive: false });
    document.removeEventListener("touchmove", funcScrollControl.scrollControl, { passive: false });
  },
  // スクロール禁止関数
  scrollControl: (event) => {
    event.preventDefault();
  }
}

export default funcScrollControl;