const funcLogo = (() => {

  window.addEventListener('scroll', function() {
    var section = document.querySelector('.sec-main');
    var logo = document.getElementById('jsLogoBottom');
    var offset = section.offsetTop + section.offsetHeight;
  
    // if (window.pageYOffset > offset) {
    //   logo.classList.remove('show');      
    // }
    if (window.pageYOffset > offset) {
      logo.style.opacity = '0';
    } else {
      logo.style.opacity = '1';
    }
  });
  


})();
export default funcLogo;