const funcNavBg = (() => {

  const hNavMainItems = document.querySelectorAll('.h-nav-main li');

  hNavMainItems.forEach((item) => {
    const bgClass = item.classList[0];
    const bgElement = document.querySelector(`.nav-bg.${bgClass}`);

    item.addEventListener('mouseenter', () => {
      bgElement.classList.add('act');
    });

    item.addEventListener('mouseleave', () => {
      bgElement.classList.remove('act');
    });
  });


})();
export default funcNavBg;